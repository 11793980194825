import React from "react";
import { Checkbox, FormControl, Text } from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import UIFormTextError from "./text-error.ui";

const UIFormCheckbox = ({
  name,
  control,
  description = "",
  textError = "",
  isInvalid,
  isRequired,
  mt = {
    base: "4",
    md: "6",
  },
  fontSize="md",
  mtError = 2
}) => {
  return (
    <FormControl mt={mt}>
      <Controller
        name={name}
        control={control}
        rules={{ required: isRequired }}
        render={({ field }) => (
          <Checkbox isInvalid={isInvalid} {...field}>
            <Text
              fontSize={fontSize}
              color={"#1a202c"}
            >
              {description}
            </Text>

          </Checkbox>
        )}
      />
      <UIFormTextError text={textError} isError={isInvalid} mt={mtError} />
    </FormControl>
  );
};

export default UIFormCheckbox;
