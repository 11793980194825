import React, { Fragment, useEffect } from "react";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const PageNotFound = ({ user }) => {
  const history = useHistory();

  const backToHome = () => {
    history.replace("/");
  }

  return (
    <Fragment>
      <div>
        <h1 style={{fontSize: "128px", color: "#CB000F", fontWeight: "700", textAlign: 'center'}}>404</h1>
        <p style={{fontSize: "24px", color: "#FFFFFF", fontWeight: "500", textAlign: 'center'}}>Halaman tidak ditemukan.</p>
        <br/>
        <div onClick={() => backToHome()} style={{cursor: 'pointer', fontSize: "16px", textDecoration: 'underline', color: "#FFFFFF", fontWeight: "500", textAlign: 'center'}}>Kembali ke Halaman Utama</div>
        <br/>
      </div>
    </Fragment>
  );
};

export default PageNotFound;
