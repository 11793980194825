const validasiTagHtml = (text) => {
  var format = /<[a-z/][\s\S]*>/i;

  if (format.test(text)) {
    return false;
  } else {
    return true;
  }
};

const formatRupiah = (angka) => {
  angka = Math.round(angka).toString();
  let prefix = "Rp. ";
  var number_string = angka.replace(/[^,\d]/g, "").toString(),
    split = number_string.split(","),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  if (ribuan) {
    let separator = sisa ? "." : "";
    rupiah += separator + ribuan.join(".");
  }

  rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
  return prefix == undefined ? rupiah : rupiah ? "Rp. " + rupiah : "";
};

const formatPoin = (angka) => {
  angka = Math.round(angka).toString();
  let prefix = "Rp. ";
  var number_string = angka.replace(/[^,\d]/g, "").toString(),
    split = number_string.split(","),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  if (ribuan) {
    let separator = sisa ? "." : "";
    rupiah += separator + ribuan.join(".");
  }

  rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
  return rupiah;
};

function calculateSize(img, maxWidth, maxHeight) {
  let width = img.width;
  let height = img.height;

  // calculate the width and height, constraining the proportions
  if (width > height) {
    if (width > maxWidth) {
      height = Math.round((height * maxWidth) / width);
      width = maxWidth;
    }
  } else {
    if (height > maxHeight) {
      width = Math.round((width * maxHeight) / height);
      height = maxHeight;
    }
  }
  return [width, height];
}

function readableBytes(bytes) {
  const i = Math.floor(Math.log(bytes) / Math.log(1024)),
    sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  return (bytes / Math.pow(1024, i)).toFixed(2) + " " + sizes[i];
}

const renderDefaultImage = (name) => {
  let arr = name.split(" ");
  return arr.join("\\n");
};

const renderLogoEmoney = (url) => {
  if(url.includes('http://')){
    url = url.replace("http://", "https://");
  }


  return url
};

export default {
  validasiTagHtml,
  formatRupiah,
  calculateSize,
  readableBytes,
  formatPoin,
  renderDefaultImage,
  renderLogoEmoney,
};
