import React, { Fragment, useEffect } from "react";

import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const PageHomeRedirect = ({ user }) => {
  const history = useHistory();

  useEffect(() => {
    history.replace("/");
  }, []);

  return <Fragment></Fragment>;
};

export default PageHomeRedirect;
