import useSWR from 'swr';
import servicesCity from '../services/services-city';

const servGetCityByIdProvince = url => servicesCity.getCityByIdProvince(url);

const useCity = (idProvince) => {
  const city = useSWR(`/city/${idProvince}`, servGetCityByIdProvince, {revalidateOnFocus: false})

  return {
    data: city?.data?.data,
    isLoading: !city?.error && !city?.data?.data,
    isError: city?.error,
  };
};

export default useCity;
