import React from "react";
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import "../modal.style.scss";
const ModalInfo = ({ status="success", message="", messageDetail="",  isOpen, onClose, size = "sm" }) => {
  return (
    <Modal
      closeOnOverlayClick={false}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
      size={size}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalBody>
          <Flex w="100%" flexDirection="column" justify="center" align="center" color={status === "success" ? "black" : "white"}>
            <Alert
              status={status}
              variant="top-accent"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              height="200px"
            >
              <AlertIcon boxSize="40px" mr={0} />
              <AlertTitle mt={4} mb={1} fontSize="lg">
                {message}
              </AlertTitle>
              <AlertDescription maxWidth="sm">
                {messageDetail}
              </AlertDescription>
            </Alert>
          </Flex>
        </ModalBody>
        <ModalFooter display="flex" justifyContent="center" alignItems="center">
          <Button
            w="130px"
            size="sm"
            rounded="md"
            color={"white"}
            border="0px"
            h="40px"
            bg={"primary.base"}
            _hover={{
              bg: "primary.hover",
            }}
            onClick={() => onClose()}
          >
            CLOSE
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalInfo;
