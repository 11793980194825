import React, { Fragment, useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Flex,
  Image,
  useDisclosure,
  Grid,
  Button,
  Text,
  Box,
} from "@chakra-ui/react";
import UITitleSection from "../../ui/title-section/title-section.ui";
import { useForm } from "react-hook-form";
import useProvince from "../../../hook/hook-province";
import useCity from "../../../hook/hook-city";
import useDistrict from "../../../hook/hook-district";
import ModalLoading from "../loading/loading.modal";
import UIFormInput from "../../ui/form/input.ui";
import UIFormSelect from "../../ui/form/select.ui";
import UIFormTextArea from "../../ui/form/text-area.ui";
import servicesCode from "../../../services/services-code";
import ModalInfo from "../info/info.modal";
import UIFormCheckbox from "../../ui/form/checkbox.ui";
import UIFormInputFile from "../../ui/form/input-file.ui";
import servicesUser from "../../../services/services-user";
import "../modal.style.scss";

const ModalBlockDesktop = ({
  user,
  isOpen,
  onClose,
  handleSuccess = () => {},
}) => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  return (
    <Fragment>
      <Modal
        closeOnOverlayClick={false}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        size={"4xl"}
        scrollBehavior="inside"
      >
        <ModalOverlay />

        <ModalContent>
          <ModalBody>
            <Image
              w="100%"
              h={{
                base: 16,
                md: 120,
              }}
              objectFit="cover"
              objectPosition={"bottom"}
              src="../assets/images/pattern7.png"
              alt=""
              position={"absolute"}
              top={0}
              left={0}
              zIndex={-1}
              roundedTop={"lg"}
            />
            <Image
              w="100%"
              h={{
                base: 16,
                md: 120,
              }}
              objectFit="cover"
              objectPosition={"top"}
              src="../assets/images/pattern6.png"
              alt=""
              position={"absolute"}
              bottom={0}
              left={0}
              zIndex={-1}
              roundedBottom={"lg"}
            />
            <Flex
              my={{
                base: 4,
                md: 4,
              }}
              mx={{
                base: 0,
                md: 0,
              }}
              flexDirection="column"
            >
              <UITitleSection
                color="black"
                p={{
                  base: "0",
                  md: "0",
                }}
                textAlign={{
                  base: "center",
                  md: "center",
                }}
              >
                INFORMASI
              </UITitleSection>

              <Text
                
                mt={6}
                fontSize={"2xl"}
                textAlign={"center"}
                color={"gray.text"}
              >
                Untuk akses fitur ini<br/>silahkan akses <Text as={"span"} color={"primary.base"}>yamalubepromo.com</Text><br/>melalui
                smartphone anda
              </Text>

              <Box my={5}></Box>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Flex justify={"center"} flexDirection="row" w="100%">
              <Button
                display={"flex"}
                minW={{
                  base: "48%",
                  md: "150px",
                }}
                mr={{
                  base: 0,
                  md: 4,
                }}
                px={4}
                py={6}
                size="sm"
                rounded="md"
                color={"white"}
                bg={"secondary.button.base"}
                _hover={{
                  bg: "secondary.button.hover",
                }}
                isLoading={false}
                onClick={() => onClose()}
                type="button"
              >
                OK
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Fragment>
  );
};

export default ModalBlockDesktop;
