import React, { Fragment } from "react";
import { Image } from "@chakra-ui/react";

const UILogoYamaha = ({
  pathDot = "."
}) => {
  return (
    <Image
      w={{ base: "150px", md: "200px", lg: "200px" }}
      objectFit="contain"
      src={`${pathDot}/assets/images/logo-yamaha.svg`}
      alt="logo yamalube"
      fallback={<div/>}
    />
  );
};

export default UILogoYamaha;
