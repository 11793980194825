import useSWR from 'swr';
import servicesProvince from '../services/services-province';
const servGetAllProvince = url => servicesProvince.getAllProvince(url);

const useProvince = () => {
  const province = useSWR('/province', servGetAllProvince, {revalidateOnFocus: false})

  return {
    data: province?.data?.data,
    isLoading: !province?.error && !province?.data?.data,
    isError: province?.error,
  };
};

export default useProvince;
