import React from "react";
import { Text, Flex, Box } from "@chakra-ui/react";

const UITitleSection = ({
  color = "black",
  children,
  p = "unset",
  textAlign = {
    base: "left",
    md: "center",
  },
  redLine = true,
  fontSize = {
    base: "3xl",
    md: "3xl",
  },
  bgGradient = true,
  mt = {
    base: "0",
    ms: "0",
  },
  mb = {
    base: 2,
    md: 4,
  },
}) => {
  return (
    <Flex
      w="max-content"
      flexDirection="column"
      justify="flex-start"
      align="flex-start"
      mb={mb}
      mt={mt}
    >
      <Text
        w={{
          base: "auto",
          md: "auto",
        }}
        textAlign={textAlign}
        fontWeight="700"
        fontSize={fontSize}
        p={p}
        color={color}
        lineHeight={{
          base: "none",
          md: "1.4rem",
        }}
      >
        {children}
      </Text>
      <Box
        mt="3"
        bg="primary.active"
        w="50%"
        h="5px"
        display={{
          base: redLine ? "block" : "none",
          md: redLine ? "block" : "none",
        }}
      ></Box>
    </Flex>
  );
};

export default UITitleSection;
