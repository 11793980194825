export const PathRoute = {
    PATH_ROOT : "/",
    PATH_ROOT_DASH : "/-",
    PATH_HOME : "/home",
    PATH_LOGIN : "/login",
    PATH_OUR_PRODUCTS : "/our-products",
    PATH_TERMS_CONDITION : "/terms-condition",
    PATH_FAQ : "/faq",
    PATH_CONTACT_US: "/contact-us",
    PATH_REGISTER: "/register",
    PATH_REGISTER_MEKANIK: "/register-mekanik",
    PATH_COMMING_SOON : "/comming-soon",
    PATH_PROFILE : "/profile",
    PATH_HADIAH : "/hadiah",
    PATH_VOUCHER_KLAIM : "/voucher-klaim",
    PATH_DETAIL_TRANSAKSI_KLAIM : "/detail-transaksi-klaim",
    PATH_KLAIM : "/klaim",
    PATH_REDEEM_POINT : "/redeem-point",
    PATH_ALL_NEWS : "/news",
    PATH_DETAIL_NEWS : "/newsdetail",
    PATH_BROCHURE_IIMS : "/brochure-iims",
    PATH_VOUCHER : "/voucher/:slug",
    PATH_VOUCHER_PROMO : "/voucher-promo",
    PATH_REGULAR_LABEL: "/regular-label",
    PATH_KATALOG: "/katalog/:slug",
}