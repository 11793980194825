import { Box, Flex, Image, Link, Text, VStack } from "@chakra-ui/react";
import parse from "html-react-parser";
import React, { Fragment } from "react";
import useDataHomePage from "../../../hook/hook-home-page";
import UIDescription from "../../ui/description/description.ui";
import UILogo from "../../ui/logo/logo.ui";

const SectionsFooter = ({ setting }) => {
  const dataHomePage = useDataHomePage();

  const menu = [
    { name: "Home", link: "/" },
    { name: "Our Products", link: "/our-products" },
    { name: "Terms & Condition", link: "/terms-condition" },
    { name: "FAQ", link: "/faq" },
  ];

  return (
    <Fragment>
      <Flex
        w="100%"
        h="auto"
        bg="black"
        justify="center"
        align="center"
        p={{
          base: "4",
          md: "0",
        }}
        pt={{
          base: "6",
          md: "14",
        }}
        pb={{
          base: "6",
          md: "14",
        }}
        position={"relative"}
      >
        <Image
          position={"absolute"}
          top={0}
          left={0}
          w="100%"
          src="../assets/images/pattern3.png"
          alt=""
          objectFit="cover"
          objectPosition={"bottom"}
          zIndex={0}
          display={
            window.location.pathname === "/" ||
            window.location.pathname === "/home"
              ? "none"
              : "block"
          }
        />

        <Flex
          experimental_spaceX={5}
          align="flex-start"
          justify="space-between"
          maxW={{ xl: "1300px" }}
          w="100%"
          h={{
            base: "auto",
            md: "auto",
          }}
          p={{
            base: "0",
            md: "0",
          }}
          mt={{
            base: 5,
            md:
              window.location.pathname === "/" ||
              window.location.pathname === "/home"
                ? "0"
                : 20,
          }}
          flexDirection={{
            base: "column",
            md: "row",
          }}
        >
          <Flex
            flex="1"
            h="100%"
            w="100%"
            flexDirection={{
              base: "column",
              md: "row",
            }}
            align="flex-start"
            mb={{
              base: "4",
              md: "0",
            }}
          >
            <Flex
              w={{
                base: "100%",
                md: "auto",
              }}
              justifyContent={"start"}
              mt={{
                base: 0,
                md: 16,
              }}
              mb={{
                base: 8,
                md: 0,
              }}
            >
              <UILogo />
            </Flex>
            <VStack
              ml={{
                base: 0,
                md: 12,
              }}
              justifyContent={"start"}
              alignItems={"start"}
            >
              <Text
                w="100%"
                fontWeight={700}
                textAlign={"left"}
                color="white"
                textTransform={"uppercase"}
                mb={{ base: 2, md: 4 }}
              >
                download my yamaha
              </Text>
              <Flex
                flexDirection={{
                  base: "row",
                  md: "row",
                }}
                justifyContent={"start"}
                alignItems={"start"}
                experimental_spaceX={2}
              >
                <VStack>
                  <a href="https://www.my-yamaha-motor.com/store/YIMM/store_link.html">
                    <Image
                      objectFit="contain"
                      src="./assets/images/logo_app_yamaha_resize.png"
                      alt="icon facebook"
                      fallback={<div />}
                      w={300}
                    />
                  </a>
                </VStack>
              </Flex>
              <Box
                h={{
                  base: 2,
                  md: 10,
                }}
              ></Box>
              <Text
                w="100%"
                fontWeight={700}
                textAlign={"left"}
                color="white"
                textTransform={"uppercase"}
              >
                IZIN UNDIAN GRATIS BERHADIAH (UGB) :
              </Text>
              <Text
                w="100%"
                textAlign={"left"}
                color="white"
                textTransform={"uppercase"}
              >
                NOMOR : 284/5.5/PI.02/03/2024
              </Text>
            </VStack>
          </Flex>
          <Flex
            flex="1"
            h="100%"
            justify="space-between"
            align="flex-start"
            flexDirection={{
              base: "column",
              md: "row",
            }}
          >
            <Flex
              w={{
                base: "100%",
                md: "40%",
              }}
              h="100%"
              flexDirection="column"
              justify="space-between"
              align="flex-start"
              mb={{
                base: "6",
                md: "0",
              }}
            >
              <Flex
                w="100%"
                h="100%"
                flexDirection="column"
                justify="flex-start"
                align="flex-start"
                mb={{
                  base: "6",
                  md: "0",
                }}
              >
                <Text
                  textAlign="left"
                  w="100%"
                  fontWeight="500"
                  color="white"
                  mb="4"
                  fontSize={{
                    base: "2xl",
                  }}
                >
                  Contact Us
                </Text>

                <Text
                  w="100%"
                  textAlign="left"
                  color={"white"}
                  fontSize="sm"
                  mb="4"
                >
                  {dataHomePage?.data?.footer &&
                    dataHomePage?.data?.footer.description &&
                    parse(dataHomePage.data.footer.description)}
                </Text>

                <Box>
                  <Flex justify="center" align="flex-start">
                    <Image
                      w="20px"
                      h="20px"
                      objectFit="contain"
                      src="./assets/images/icon_phone_new.svg"
                      alt="icon phone"
                      mr="2"
                      fallback={<div />}
                    />
                    <Text
                      w="100%"
                      textAlign="left"
                      color={"white"}
                      fontSize="sm"
                    >
                      <Link href={`tel:${setting?.phone_app}`}>
                        {setting?.phone_app}
                      </Link>
                    </Text>
                  </Flex>
                  <Flex justify="center" align="flex-start" mt={2}>
                    <Image
                      w="20px"
                      h="20px"
                      objectFit="contain"
                      src="./assets/images/icon_email_new.svg"
                      alt="icon email"
                      mr="2"
                      fallback={<div />}
                    />
                    <Text
                      w="100%"
                      textAlign="left"
                      color={"white"}
                      fontSize="sm"
                    >
                      <Link href={`mailto:${setting?.email_app}`}>
                        {setting?.email_app}
                      </Link>
                    </Text>
                  </Flex>
                </Box>
              </Flex>

              {/* <Text w="100%" textAlign="left" color={"white"} fontSize="sm">
                <Link href={setting?.google_map_url} target="_blank">
                  Find us on Google Maps
                </Link>
              </Text> */}
            </Flex>
            <Flex
              w={{
                base: "100%",
                md: "40%",
              }}
              h="100%"
              flexDirection="column"
              justify="space-between"
              align="flex-start"
              mb={{
                base: "6",
                md: "0",
              }}
            >
              <Flex
                w="100%"
                h="100%"
                flexDirection="column"
                justify="flex-start"
                align="flex-start"
                mb={{
                  base: "6",
                  md: "0",
                }}
              >
                <Text
                  textAlign="left"
                  w="100%"
                  fontWeight="500"
                  color="white"
                  mb="4"
                  fontSize={{
                    base: "2xl",
                  }}
                >
                  Quick Links
                </Text>

                <Box>
                  {menu.map((item, key) => (
                    <Link to={item.link} key={key}>
                      <Text
                        w="100%"
                        textAlign="left"
                        color={"white"}
                        fontSize="sm"
                        _hover={{ color: "white" }}
                      >
                        {item.name}
                      </Text>
                    </Link>
                  ))}
                </Box>
              </Flex>

              {/* <Flex w="100%">
                <Link
                  href={
                    "https://web.facebook.com/Aslilebihbaik-150931782275309"
                  }
                  target="_blank"
                  mr={2}
                  filter="brightness(0.8)"
                  _hover={{ filter: "brightness(1)" }}
                >
                  <Image
                    w={{ base: "35px" }}
                    h={{ base: "35px" }}
                    objectFit="contain"
                    src="./assets/images/icon_facebook.png"
                    alt="icon facebook"
                    fallback={<div />}
                  />
                </Link>
                <Link
                  href={"https://twitter.com/aslilebihbaik"}
                  target="_blank"
                  mr={2}
                  filter="brightness(0.8)"
                  _hover={{ filter: "brightness(1)" }}
                >
                  <Image
                    w={{ base: "35px" }}
                    h={{ base: "35px" }}
                    objectFit="contain"
                    src="./assets/images/icon_twitter.png"
                    alt="icon facebook"
                    fallback={<div />}
                  />
                </Link>
                <Link
                  href={"https://www.instagram.com/aslilebihbaik/?hl=id"}
                  target="_blank"
                  mr={2}
                  filter="brightness(0.8)"
                  _hover={{ filter: "brightness(1)" }}
                >
                  <Image
                    w={{ base: "35px" }}
                    h={{ base: "35px" }}
                    objectFit="contain"
                    src="./assets/images/icon_instagram.png"
                    alt="icon facebook"
                    fallback={<div />}
                  />
                </Link>
              </Flex> */}
            </Flex>
          </Flex>
        </Flex>
      </Flex>

      <Flex w="100%" h="auto" bg="#CAB28B" justify="center" align="center">
        <Flex
          align="flex-start"
          justify="space-between"
          maxW={{ xl: "1300px" }}
          w="100%"
          h="100%"
          p={{
            base: "4",
            md: "0",
          }}
          pt={{
            base: "4",
            md: "4",
          }}
          pb={{
            base: "4",
            md: "4",
          }}
        >
          <Text
            fontWeight={"500"}
            w={"100%"}
            textAlign="center"
            fontSize={{
              base: "sm",
              md: "md",
            }}
            color={"black"}
          >
            Copyrights © 2024 All Rights Reserved by Yamaha Motor Indonesia
          </Text>
        </Flex>
      </Flex>
    </Fragment>
  );
};

export default SectionsFooter;
