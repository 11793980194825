import React from "react";
import { Text } from "@chakra-ui/react";

const UIFormTextLabel = ({ label, labelExtend, isRequired = false, color = "white" }) => {
  return (
    <Text color={color} fontWeight="700">
      {label}{" "}
      {labelExtend && (
        <Text as={"span"} color="primary.base">
          {labelExtend}
        </Text>
      )}
      {isRequired && (
        <Text color="primary.base" display="inline-block">
          *
        </Text>
      )}
    </Text>
  );
};

export default UIFormTextLabel;
