import React from "react";
import { FormControl, FormLabel } from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import UIFormTextLabel from "./text-label.ui";
import UIFormTextError from "./text-error.ui";

const selectStyles = {
  control: (styles) => ({ ...styles, height: "50px" }),
};

const selectErrorStyles = {
  control: (styles) => ({
    ...styles,
    height: "50px",
    borderColor: "#CB000F",
    boxShadow: "0 0 0 1px #cb000f",
  }),
};

const UIFormSelect = ({
  name,
  label,
  placeholder="",
  control,
  isLoading,
  options,
  textError="",
  isInvalid,
  isRequired = false,
  isDisabled = false,
  defaultValue = "",
  getOptionLabel = (option) => option.name,
  getOptionValue = (option) => option.id,
  mt = 0,
  mb = 0,
  colorLabel = "white"
}) => {
  return (
    <FormControl mt={mt} mb={mb}>
      <FormLabel>
        <UIFormTextLabel color={colorLabel} label={label} isRequired={isRequired} />
      </FormLabel>
      <Controller
        name={name}
        control={control}
        rules={{ required: isRequired }}
        render={({ field }) => (
          <Select
            {...field}
            isLoading={isLoading}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            options={options}
            styles={isInvalid ? selectErrorStyles : selectStyles}
            placeholder={placeholder}
            isDisabled={isDisabled}
            defaultValue={defaultValue}
          />
        )}
      />
      <UIFormTextError text={textError} isError={isInvalid} />
    </FormControl>
  );
};

export default UIFormSelect;
