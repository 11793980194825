import React, { Fragment } from "react";
import { Box, Flex, Link, Text, Image, Center } from "@chakra-ui/react";
import UIDescription from "../../ui/description/description.ui";
import UILogo from "../../ui/logo/logo.ui";
import UIImageQrCode from "../../ui/image-qrcode/image-qrcode.ui";
import useDataHomePage from "../../../hook/hook-home-page";
import parse from "html-react-parser";

const SectionsFooterBrochureIIMS = ({ setting }) => {
  return (
    <Fragment>
      <Flex
        w="100%"
        h="auto"
        bg="secondary.blue"
        justify="center"
        align="center"
      >
        <Flex
          align="flex-start"
          justify="space-between"
          w="100%"
          h="100%"
          p={{
            base: "4",
            md: "0",
          }}
          pt={{
            base: "4",
            md: "4",
          }}
          pb={{
            base: "4",
            md: "4",
          }}
        >
          <UIDescription color="white">
            <Center p={3}>PT Yamaha Indonesia Motor Manufacturing - 2022</Center>
          </UIDescription>
        </Flex>
      </Flex>
    </Fragment>
  );
};

export default SectionsFooterBrochureIIMS;
