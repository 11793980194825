import React, { Fragment } from "react";
import { Flex } from "@chakra-ui/react";
import SectionHeader from "../sections/header/header.section";
import SectionFooter from "../sections/footer/footer.section";
import SectionNavMobile from "../sections/nav-mobile/nav-mobile.section";
import { useLocation } from "react-router-dom";
import { PathRoute } from "../../constant/route.constant";
import SectionHeaderBrochureIIMS from "../sections/header/header-brochure-iims.section";
import SectionsFooterBrochureIIMS from "../sections/footer/footer-brochure-iims.section";
import SectionHeaderVoucher from "../sections/header/header-voucher.section";
import SectionsFooterVoucher from "../sections/footer/footer-voucher.section";
import SectionHeaderKatalog from "../sections/header/header-katalog.section";
import SectionsFooterKatalog from "../sections/footer/footer-katalog.section";

const BaseLayout = ({ user, children, setting, ...props }) => {
  const location = useLocation();


  const checkConditionalPage = () => {
    if(location.pathname === PathRoute.PATH_BROCHURE_IIMS)
      return "brosur_iims";
    else  if(location.pathname.includes("katalog"))
      return "katalog";
    // else  if(location.pathname.includes("voucher"))
    //   return "voucher";
    else
      return "default";
  }

  const checkPathName = () => {
 
    switch(checkConditionalPage())
    {
      case "brosur_iims":
        return false;
      case "voucher":
        return false;
      case "katalog":
        return false;
      default:
        return true;
    }
  };

  const generateCustomHeader = () => {
    switch(checkConditionalPage()){
      case "brosur_iims":
        return <SectionHeaderBrochureIIMS />;
      case "voucher":
        return <SectionHeaderVoucher />;
      case "katalog":
        return <SectionHeaderKatalog />;
      default:
        return <SectionHeaderBrochureIIMS />;
    }

  };

  const generateCustomFooter = () => {
    switch(checkConditionalPage()){
      case "brosur_iims":
        return <SectionsFooterBrochureIIMS />;
      // case "voucher":
      //   return <SectionsFooterVoucher />;
      case "katalog":
        return <SectionsFooterKatalog />;
      default:
        return <SectionsFooterBrochureIIMS />;
    }
  };

  const generateBgColor = () => {
    switch(checkConditionalPage()){
      case "brosur_iims":
        return "white";
      case "voucher":
        return "transparent";
      case "katalog":
        return "white";
      default:
        return "transparent";
    }
  };

  return (
    <Fragment>
      <Flex
        bg={generateBgColor()}
        direction="column"
        align="center"
        minH={window.innerHeight}
        m="0 auto"
        pt="90px"
        {...props}
      >
        <Flex
          w="100%"
          h="90px"
          top="0"
          bg="black"
          justify="center"
          align="center"
          position="fixed"
          zIndex="9"
        >
          {checkPathName() ? (
            <SectionHeader user={user} />
          ) : (
            generateCustomHeader()
          )}
        </Flex>
        {children}

        <Flex
          w="100%"
          h="auto"
          bg="black"
          direction="column"
          justify="center"
          align="center"
        >
          {checkPathName() ? (
            <SectionFooter setting={setting} />
          ) : (
            generateCustomFooter()
          )}
        </Flex>
      </Flex>

      {checkPathName() && <SectionNavMobile user={user} />}
    </Fragment>
  );
};
export default BaseLayout;
