import API from "./http-common";

const getCityByIdProvince = (url) => {
  return API.http()
    .get(url)
    .then((res) => {
      return res.data;
    })
    .catch((e) => {
      return false;
    });
};

export default {
  getCityByIdProvince,
};
