import cookies from "js-cookie";

const setCookiesToken = (access_token, token_type) => {
  cookies.set("access_token", access_token);
  cookies.set("token_type", token_type);
};

const removeCookiesToken = () => {
  cookies.remove("access_token");
  cookies.remove("token_type");
};

const getCookiesFirstLogin = () => {
  return cookies.get("first_login");
}

const setCookiesFirstLogin = (val) => {
  cookies.set("first_login", val);
};

const removeCookiesFirstLogin = () => {
  cookies.remove("first_login");
};

const setCookiesUniqueCode = (unique_code) => {
  cookies.set("unique_code", unique_code);
}

const getCookiesUniqueCode = () => {
  return cookies.get("unique_code");
}

const removeCookiesUniqueCode = () => {
  cookies.remove("unique_code");
}

const setGift = (gift) => {
  cookies.set("gift", JSON.stringify(gift));
}

const getGift = () => {
  let _gift = cookies.get("gift");
  if(_gift){
    return JSON.parse(_gift);
  }
  else{
    return _gift;
  }
  
}

const removeGift = () => {
  cookies.remove("gift");
}

const setCookiesRegularLabel = (val) => {
  cookies.set("regular_label", val);
};

const getCookiesRegularLabel = () => {
  return cookies.get("regular_label");
}

const removeCookiesRegularLabel = () => {
  cookies.remove("regular_label");
};


const setCookiesDemo = (val) => {
  cookies.set("demo", val);
};

const getCookiesDemo = () => {
  return cookies.get("demo");
}

const removeCookiesDemo = () => {
  cookies.remove("demo");
};

export default {
  setCookiesToken,
  removeCookiesToken,
  getCookiesFirstLogin,
  setCookiesFirstLogin,
  removeCookiesFirstLogin,
  setCookiesUniqueCode,
  getCookiesUniqueCode,
  removeCookiesUniqueCode,
  setGift,
  getGift,
  removeGift,
  setCookiesRegularLabel,
  getCookiesRegularLabel,
  removeCookiesRegularLabel,
  setCookiesDemo,
  getCookiesDemo,
  removeCookiesDemo
};
