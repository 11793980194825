import { Fragment, lazy, Suspense, useEffect, useRef, useState } from "react";
import { Route, Switch } from "react-router";
import { PathRoute } from "./constant/route.constant";
import BaseLayout from "./components/layouts/base-layout.layout";
import "./App.css";
import { useHistory, useLocation } from "react-router-dom";
import { useDisclosure, Text, Box, Flex } from "@chakra-ui/react";
import useUser from "./hook/hook-user";
import ModalLoading from "./components/modals/loading/loading.modal";
import userCookies from "./utils/user.cookies";
import useSetting from "./hook/hook-setting";
import MetaTags from "react-meta-tags";
import parse from "html-react-parser";

import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import UILogo from "./components/ui/logo/logo.ui";
import PageHomeRedirect from "./pages/home/home-redirect.page";
import PageNotFound from "./pages/home/notfound.page";

const App = () => {
  let isMaintenace =
    process.env.REACT_APP_MAINTENANCE_MODE.toLowerCase() === "true";

  const tawkMessengerRef = useRef();

  const setting = useSetting();
  const traceUser = false;
  const history = useHistory();
  const location = useLocation();
  const user = useUser();
  const modalLoading = useDisclosure();

  const PageHome = lazy(() => import("./pages/home/home.page"));
  const PageLogin = lazy(() => import("./pages/login/login.page"));
  const PageRegistrasiMekanik = lazy(() =>
    import("./pages/registrasi-mekanik/registrasi-mekanik.page")
  );

  const PageOurProducts = lazy(() =>
    import("./pages/our-products/our-products.page")
  );

  const PageVoucherPromo = lazy(() =>
    import("./pages/voucher-promo/voucher-promo.page")
  );

  const PageVoucher = lazy(() => import("./pages/voucher/voucher.page"));

  const PageBrochureIIMS = lazy(() =>
    import("./pages/brochure-iims/brochure-iims.page")
  );

  const PageKatalog = lazy(() => import("./pages/katalog/katalog.page"));
  const PageTermsCondition = lazy(() =>
    import("./pages/terms-condition/terms-condition.page")
  );
  const PageFAQ = lazy(() => import("./pages/faq/faq.page"));
  const PageContactUs = lazy(() =>
    import("./pages/contact-us/contact-us.page")
  );
  const PageProfile = lazy(() => import("./pages/profile/profile.page"));
  const PageHadiah = lazy(() => import("./pages/hadiah/hadiah.page"));
  const PageVoucherKlaim = lazy(() =>
    import("./pages/voucher-klaim/voucher-klaim.page")
  );
  const PageDetailTransaksiKlaim = lazy(() =>
    import("./pages/detail-transkasi-klaim/detail-transkasi-klaim.page")
  );
  const PageKlaim = lazy(() => import("./pages/klaim/klaim.page"));
  const PageRedeemPoint = lazy(() =>
    import("./pages/redeem-point/redeem-point.page")
  );

  const PageNews = lazy(() => import("./pages/news/news.page"));
  const PageAllNews = lazy(() => import("./pages/news/all-news.page"));

  const PageRegularLabel = lazy(() =>
    import("./pages/regular-label/regular-label.page")
  );

  const [showChat, setShowChat] = useState(false);

  useEffect(() => {
    let unique_code = userCookies.getCookiesUniqueCode();
    if (unique_code || unique_code === "") {
      userCookies.removeCookiesUniqueCode();
    }

    let gift = userCookies.getGift();
    if (gift || gift === "") {
      userCookies.removeGift();
    }
  }, []);

  useEffect(() => {
    if (user) {
      if (user?.data?.meta?.code === 401) {
        if (
          location.pathname === PathRoute.PATH_REGISTER ||
          location.pathname === PathRoute.PATH_REGISTER_MEKANIK ||
          location.pathname === PathRoute.PATH_REGULAR_LABEL
        ) {
          history.push(PathRoute.PATH_HOME);
        }
      } else {
        if (user?.data) {
          if (user?.data?.type == null) {
            if (location.pathname !== PathRoute.PATH_REGISTER_MEKANIK) {
              history.push(PathRoute.PATH_REGISTER_MEKANIK);
            }
          } else {
            setShowChat(true);

            if (location.pathname === PathRoute.PATH_REGISTER_MEKANIK) {
              let firstLogin = userCookies.getCookiesFirstLogin();
              if (firstLogin === "true") {
                userCookies.removeCookiesFirstLogin();
                history.push(PathRoute.PATH_PROFILE);
              }
              history.push(PathRoute.PATH_PROFILE);
              return;
            }

            if (location.pathname === PathRoute.PATH_LOGIN) {
              let regularLabel = userCookies.getCookiesRegularLabel();
              if (regularLabel === "true") {
                userCookies.removeCookiesRegularLabel();
                history.push(PathRoute.PATH_REGULAR_LABEL);
                return;
              } else {
                let firstLogin = userCookies.getCookiesFirstLogin();
                if (firstLogin === "true") {
                  userCookies.removeCookiesFirstLogin();
                  history.push(PathRoute.PATH_PROFILE);
                  return;
                }
              }
            }

            if (location.pathname !== PathRoute.PATH_REGULAR_LABEL) {
              let regularLabel = userCookies.getCookiesRegularLabel();
              if (regularLabel === "true") {
                userCookies.removeCookiesRegularLabel();
                history.push(PathRoute.PATH_REGULAR_LABEL);
                return;
              } else {
                let firstLogin = userCookies.getCookiesFirstLogin();
                if (firstLogin === "true") {
                  userCookies.removeCookiesFirstLogin();
                  history.push(PathRoute.PATH_PROFILE);
                  return;
                }
              }
            }
          }
        }
      }
    }
  }, [user]);

  const onLoad = () => {
    setPositionMobileTawk();
  };

  useEffect(() => {
    setPositionMobileTawk();
  }, [tawkMessengerRef.current]);

  const setPositionMobileTawk = () => {
    setTimeout(() => {
      const iframe = document.getElementsByTagName("iframe");
      let iframeObject = null;
      let i = 0;
      while (i < iframe.length) {
        if (
          iframe[i].getAttribute("height") == "64px" &&
          iframe[i].getAttribute("width") == "67px" &&
          iframe[i].getAttribute("title") == "chat widget"
        ) {
          iframeObject = iframe[i];
          break;
        }

        i++;
      }

      if (iframeObject !== null) {
        iframeObject.style.bottom = "80px";
        iframeObject.style.right = "0px";
      }
    }, 100);
  };

  const a = true;

  return (
    <Fragment>
      {isMaintenace ? (
        <>
          {" "}
          <Box
            fontSize={{
              base: "16px",
              md: "22px",
            }}
            marginTop="120px"
            flexDirection="column"
            color="white"
            textAlign="center"
            padding={2}
          >
            <Flex justifyContent="center">
              <UILogo />
            </Flex>
            <br />
            <br />
            {parse(process.env.REACT_APP_MAINTENANCE_MSG)}
          </Box>
        </>
      ) : (
        <>
          <MetaTags>
            <title>{setting?.data?.website_title}</title>
            <meta
              name="description"
              content={setting?.data?.meta_description}
            />
            <meta name="keyword" content={setting?.data?.meta_keywords} />
          </MetaTags>

          {showChat === true && (
            <Flex color="white" w={256}>
              <TawkMessengerReact
                propertyId={process.env.REACT_APP_TAWK_TO_PROPERTY_ID}
                widgetId={process.env.REACT_APP_TAWK_TO_WIDGET_ID}
                ref={tawkMessengerRef}
                onLoad={onLoad}
              />
            </Flex>
          )}

          {user?.isLoading && (
            <ModalLoading
              isOpen={true}
              onClose={modalLoading?.onClose}
              isError={user?.isError}
            />
          )}

          {traceUser && (
            <Text
              color="black"
              top="100px"
              w="100%"
              bg="white"
              position="fixed"
              zIndex="9999"
            >
              userLoading : {JSON.stringify(user)}
            </Text>
          )}

          {!user?.isLoading && (
            <Suspense fallback={<div></div>}>
              <BaseLayout user={user} setting={setting?.data}>
                <Switch>
                  <Route exact path={PathRoute.PATH_ROOT}>
                    <PageHome user={user} />
                  </Route>
                  <Route exact path={PathRoute.PATH_ROOT_DASH}>
                    <PageHomeRedirect user={user} />
                  </Route>
                  <Route exact path={PathRoute.PATH_LOGIN}>
                    <PageLogin userData={user} />
                  </Route>
                  <Route exact path={PathRoute.PATH_REGISTER_MEKANIK}>
                    <PageRegistrasiMekanik user={user} />
                  </Route>
                  <Route exact path={PathRoute.PATH_HOME}>
                    <PageHome user={user} />
                  </Route>
                  <Route exact path={PathRoute.PATH_OUR_PRODUCTS}>
                    <PageOurProducts />
                  </Route>
                  <Route exact path={PathRoute.PATH_VOUCHER_PROMO}>
                    <PageVoucherPromo />
                  </Route>
                  <Route exact path={PathRoute.PATH_TERMS_CONDITION}>
                    <PageTermsCondition />
                  </Route>
                  <Route exact path={PathRoute.PATH_FAQ}>
                    <PageFAQ />
                  </Route>
                  <Route exact path={PathRoute.PATH_CONTACT_US}>
                    <PageContactUs />
                  </Route>
                  <Route exact path={PathRoute.PATH_PROFILE}>
                    <PageProfile user={user} />
                  </Route>
                  <Route exact path={PathRoute.PATH_HADIAH}>
                    <PageHadiah user={user} />
                  </Route>
                  <Route exact path={PathRoute.PATH_VOUCHER_KLAIM}>
                    <PageVoucherKlaim user={user} />
                  </Route>
                  <Route exact path={PathRoute.PATH_DETAIL_TRANSAKSI_KLAIM}>
                    <PageDetailTransaksiKlaim user={user} />
                  </Route>
                  <Route exact path={PathRoute.PATH_KLAIM}>
                    <PageKlaim user={user} />
                  </Route>
                  <Route exact path={PathRoute.PATH_REDEEM_POINT}>
                    <PageRedeemPoint user={user} />
                  </Route>
                  <Route exact path={PathRoute.PATH_DETAIL_NEWS}>
                    <PageNews />
                  </Route>
                  <Route exact path={PathRoute.PATH_ALL_NEWS}>
                    <PageAllNews />
                  </Route>
                  <Route exact path={PathRoute.PATH_BROCHURE_IIMS}>
                    <PageBrochureIIMS />
                  </Route>
                  <Route exact path={PathRoute.PATH_VOUCHER}>
                    <PageVoucher />
                  </Route>
                  <Route exact path={PathRoute.PATH_REGULAR_LABEL}>
                    <PageRegularLabel user={user} />
                  </Route>
                  <Route exact path={PathRoute.PATH_KATALOG}>
                    <PageKatalog />
                  </Route>
                  {/* 404 Page Route */}
                  <Route>
                    <PageNotFound />
                  </Route>
                </Switch>
              </BaseLayout>
            </Suspense>
          )}
        </>
      )}
    </Fragment>
  );
};

export default App;
