import { extendTheme, theme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools"


const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
};

const colors = {
  transparent: "transparent",
  brown: "#AE8625",
  black: "#000",
  white: "#fff",
  red: "#CA000E",
  brown_2: "#CAB28B",
  brown_3: "#FAD8B0",
  orange: "#FF9C41",
  gray: {
    50: "#f7fafc",
    100: "#e4e4e4",
    900: "#171923",
    text: "#1F1F1F",
    text_2: "#B7B5B5",
    text_3: "#979797",
    bg: "#EFEFEF"
  },
  primary: {
    base: "#CB000F",
    hover: "#f10517",
    active: "#e11e25"
  },
  secondary: {
    base: "#060F6D",
    blue: "#063CFB",
    button: {
      base: "#0e1ec7",
      hover: "#1226f7"
    }
  },
  tertiary:{
    base: "#f4cc2d"
  },
  text: {
    input: "#959595"
  }
};

const sizes = {
  ...theme.space
};

const breakpoints = createBreakpoints({
  sm: "320px",
  md: "768px",
  lg: "960px",
  xl: "1200px"
})

const YamalubeTheme = extendTheme({ config, colors, breakpoints, sizes });

export default YamalubeTheme;
