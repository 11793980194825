import React, { Fragment } from "react";
import { Image } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { PathRoute } from "../../../constant/route.constant";
import logoHeader from "../../../assets/logo.svg";

const UILogo = () => {
  const history = useHistory();
  return (
    <Image
      w={{ base: "150px", md: "200px", lg: "200px" }}
      objectFit="contain"
      src={logoHeader}
      alt="logo yamalube"
      fallback={<div />}
      onClick={() => history.push(PathRoute.PATH_HOME)}
      cursor="pointer"
    />
  );
};

export default UILogo;
