import React, { Fragment } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  Flex,
  Text,
  Center,
  Image,
  useDisclosure,
  Box,
} from "@chakra-ui/react";
import ModalLogin from "../../modals/login/login.modal";
import userCookies from "../../../utils/user.cookies";
import { PathRoute } from "../../../constant/route.constant";
import { MobileView, IOSView } from "react-device-detect";

const SectionNavMobile = ({ user }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const history = useHistory();

  const handleLogout = () => {
    userCookies.removeCookiesToken();
    user?.mutate();
    window.location.reload();
  };

  const menu = [
    {
      name: "Home",
      icon_url: "./assets/images/icon_home_new.svg",
      icon_url_active: "./assets/images/icon_home_new_active.svg",
      link: "/",
    },
    {
      name: "Products",
      icon_url: "./assets/images/icon_product_new.svg",
      icon_url_active: "./assets/images/icon_product_new_active.svg",
      link: "/our-products",
    },
    {
      name: "T&C",
      icon_url: "./assets/images/icon_t_and_c_new.svg",
      icon_url_active: "./assets/images/icon_t_and_c_new_active.svg",
      link: "/terms-condition",
    },
    {
      name: "FAQ",
      icon_url: "./assets/images/icon_faq_new.svg",
      icon_url_active: "./assets/images/icon_faq_new_active.svg",
      link: "/faq",
    },
    {
      name: "Daftar/Masuk",
      icon_url: "./assets/images/icon_profile_new.svg",
      icon_url_active: "./assets/images/icon_profile_new.svg",
      link: "func",
    },
  ];
  return (
    <Fragment>
      <ModalLogin isOpen={isOpen} onClose={onClose} size="xs" userData={user} />

      {/* NAV MOBILE */}
      <Flex
        pos="sticky"
        bottom={"0"}
        color="white"
        display={{ base: "flex", lg: "none" }}
        flexDirection={"column"}
        w="100%"
        zIndex={9}
      >
        <Flex color="white" display={{ base: "flex", lg: "none" }} w="100%">
          {menu.map((item, key) => (
            <Box flex="1" px={2} pt={1} bg="primary.active">
              <Center
                key={`nav_mobile_${key}`}
                flex="1"
                bg={
                  location.pathname === item.link ? "#CAB28B" : "primary.active"
                }
              >
                {item.link !== "func" && (
                  <Link to={item.link}>
                    <Flex
                      justify="space-between"
                      align="center"
                      flexDirection="column"
                      p={1}
                    >
                      <Image
                        w={"20px"}
                        h={"20px"}
                        objectFit="contain"
                        src={
                          location.pathname === item.link
                            ? item.icon_url_active
                            : item.icon_url
                        }
                        alt={`Icon ${item.name}`}
                        mb={1}
                        fallback={<div />}
                      />

                      <Text
                        fontSize={"xs"}
                        noOfLines={1}
                        color={
                          location.pathname === item.link ? "black" : "white"
                        }
                      >
                        {item.name}
                      </Text>
                    </Flex>
                  </Link>
                )}

                {item.link === "func" && (
                  <Fragment>
                    {!user?.data?.member_number ? (
                      <div onClick={() => onOpen()}>
                        <Flex
                          justify="space-between"
                          align="center"
                          flexDirection="column"
                          p={1}
                        >
                          <Image
                            w={"20px"}
                            h={"20px"}
                            objectFit="contain"
                            src={
                              location.pathname === item.link
                                ? item.icon_url_active
                                : item.icon_url
                            }
                            alt={`Icon ${item.name}`}
                            mb={1}
                            fallback={<div />}
                          />

                          <Text fontSize={"xs"} noOfLines={1}>
                            {item.name}
                          </Text>
                        </Flex>
                      </div>
                    ) : user?.data?.type === null ? (
                      <div onClick={() => handleLogout()}>
                        <Flex
                          justify="space-between"
                          align="center"
                          flexDirection="column"
                          p={1}
                        >
                          <Image
                            w={"20px"}
                            h={"20px"}
                            objectFit="contain"
                            src={
                              location.pathname === item.link
                                ? item.icon_url_active
                                : item.icon_url
                            }
                            alt={`Icon ${item.name}`}
                            mb={2}
                            fallback={<div />}
                          />

                          <Text fontSize={"xs"} noOfLines={1}>
                            Logout
                          </Text>
                        </Flex>
                      </div>
                    ) : (
                      <Fragment>
                        {location.pathname === PathRoute.PATH_PROFILE ? (
                          <div onClick={() => handleLogout()}>
                            <Flex
                              justify="space-between"
                              align="center"
                              flexDirection="column"
                              p={1}
                            >
                              <Image
                                w={"20px"}
                                h={"20px"}
                                objectFit="contain"
                                src={
                                  location.pathname === item.link
                                    ? item.icon_url_active
                                    : item.icon_url
                                }
                                alt={`Icon ${item.name}`}
                                mb={1}
                                fallback={<div />}
                              />

                              <Text fontSize={"xs"} noOfLines={1}>
                                Logout
                              </Text>
                            </Flex>
                          </div>
                        ) : (
                          <div onClick={() => history.push("/profile")}>
                            <Flex
                              justify="space-between"
                              align="center"
                              flexDirection="column"
                              p={1}
                            >
                              <Image
                                w={"20px"}
                                h={"20px"}
                                objectFit="contain"
                                src={
                                  location.pathname === item.link
                                    ? item.icon_url_active
                                    : item.icon_url
                                }
                                alt={`Icon ${item.name}`}
                                mb={1}
                                fallback={<div />}
                              />

                              <Text fontSize={"xs"} noOfLines={1}>
                                Profile
                              </Text>
                            </Flex>
                          </div>
                        )}
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </Center>
            </Box>
          ))}
        </Flex>
        <IOSView>
          <Box bg="black" h={5} w="100%"></Box>
        </IOSView>
      </Flex>
    </Fragment>
  );
};

export default SectionNavMobile;
