import axios from "axios";
import cookies from "js-cookie";

const http = () => {
  let headers = {
    "Content-type": "application/json",
  };



  if (cookies.get("access_token")) {
    // console.log("===");
    // console.log(cookies.get("access_token"));
    // console.log("====")
    let access_token = cookies.get("access_token");
    let token_type = cookies.get("token_type");

    axios.defaults.headers.common["Authorization"] = "";
    delete axios.defaults.headers.common["Authorization"];

    axios.defaults.headers.common[
      "Authorization"
    ] = `${token_type} ${access_token}`;

    headers.Authorization = `${token_type} ${access_token}`;
  }
  else{
    axios.defaults.headers.common["Authorization"] = "";
    delete axios.defaults.headers.common["Authorization"];
  }

  return axios.create({
    baseURL: process.env.REACT_APP_URL_API,
    headers: headers,
  });
};

const httpFile = () => {
  let headers = {
    'Content-Type': 'multipart/form-data'
  };

  if (cookies.get("access_token")) {
    let access_token = cookies.get("access_token");
    let token_type = cookies.get("token_type");

    axios.defaults.headers.common["Authorization"] = "";
    delete axios.defaults.headers.common["Authorization"];

    axios.defaults.headers.common[
      "Authorization"
    ] = `${token_type} ${access_token}`;

    headers.Authorization = `${token_type} ${access_token}`;

   
  }
  else{
    axios.defaults.headers.common["Authorization"] = "";
    delete axios.defaults.headers.common["Authorization"];
  }

  return axios.create({
    baseURL: process.env.REACT_APP_URL_API,
    headers: headers,
  });
};

export default { http, httpFile };
