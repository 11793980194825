import useSWR from 'swr';
import servicesHome from '../services/services-home';

const servGetDataHomePage = url => servicesHome.getDataHomePage(url);

const useDataHomePage = () => {
  const dataHomePage = useSWR(`/homepage`, servGetDataHomePage, {revalidateOnFocus: false})

  return {
    data: dataHomePage?.data?.data,
    isLoading: !dataHomePage?.error && !dataHomePage?.data?.data,
    isError: dataHomePage?.error,
  };
};

export default useDataHomePage;
