import React from "react";
import { Text } from "@chakra-ui/react";

const UIDescription = ({
  color = "white",
  children,
  fontWeight="normal",
  textAlign = {
    base: "left",
    md: "left",
  },
  w="100%",
  mb,
}) => {
  return (
    <Text
      fontWeight={fontWeight}
      w={w}
      textAlign={textAlign}
      fontSize={{
        base: "sm",
        md: "md",
      }}
      color={color}
      mb={mb ? mb : 0}
    >
      {children}
    </Text>
  );
};

export default UIDescription;
